.home-page header {
    background-image: url(../utils/img/header-img.jpg);
}

header {
      background: url('https://t4.ftcdn.net/jpg/02/92/20/37/360_F_292203735_CSsyqyS6A4Z9Czd4Msf7qZEhoxjpzZl1.jpg') center/cover no-repeat fixed;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      
      position: relative;
      z-index: 1;
    
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Dark overlay */
        z-index: -1;
      }

}

header h1 {
    font-size: 5rem;
}

.home-page header h1,
.home-page header h2 {
    text-shadow: 2px 2px 8px #ffffff;
}

.menu-section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../utils/img/menu-img.jpg);
    background-size: cover;
    background-position: center;
}

img {
    border-style: solid;
    border-width: 5px;
}

@media only screen and (max-width: 768px) {
    header h1 {
        font-size: 4rem;
    }
}

@media only screen and (max-width: 576px) {
    header h1 {
        font-size: 3rem;
    }
}
.logo-img {
    height: 200px;
    width: auto; /* Adjust width if needed */
}
